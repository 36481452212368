body{
  font-family: 'Noto Sans', sans-serif;
  background-color:#F4F3EE;
}

.App {
  text-align: center;
}

#navbar{
  display:flex;
  justify-content: space-between;
  background-color:#E4DFDA; /* <light dark> #05204A; /*#0f7173; /* Nav color */
  align-items: center;
}
.navItem{
  font-size: 20px;

}
a{
  text-decoration:none;
  color:#051923;
}


#logo{
  margin-left:1.5%;
}

#link{
  margin-right:2.5%;

}

nav img:hover, a:hover{
  opacity: 0.5;
}

.projects{
  display: inline-block;
  margin: 70px 30px 0px 70px;
  border: 1px black solid;
  border-radius: 5px;
  width: 350px;
}
#programs{
  display:flex;
  flex-direction: row;
  justify-content: center;

}

#projects{
  display:flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

#bryanImg {
  border-radius: 50%;
}

#bryanDiv {
  margin-left:20%;
  width:60%;
  font-size: 20px;
}



#bryanDiv {
  margin-bottom:10%;
}

.sideMenu {
  display: none;
  cursor: pointer;
}

#sideMenuItems{
 
 border: 1px solid black;
 border-radius: 5px;

}

.expanded {
  width: 60%;
  width: 80%;
  display: inline-block;
  border: 1px black solid;
  border-radius: 5px;
}



#internships{
  display:flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.internships{
  display: inline-block;
  border: 1px black solid;
  border-radius: 5px;
  width: 350px;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.dropDownTop{
  background-color: #EE4D25;
  padding: 10px;
}

.dropDownBottom{
  background-color:#BCB8B1;
  margin:0px;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

#horiMenu{
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.hide{
  display: none;
}

#langs, #tech{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

@media only screen and (max-width: 1000px){
  .sideMenu {
    display: inline-block;
    cursor: pointer;
  }
  #horiMenu{
    display: none;
  }
}